import { API_HOST, APP_NAME, intl, enrichedAuthenticatedFetchData } from '../lib'
import { pushMessage } from '../components/AppMessages'
import { logError } from '@tomra/datadog-browser-logging'
import { Common_FetchError } from '../translations/messages'

export function fetchCharityCampaign(campaignId, signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/charities/browse/v1/campaign/${campaignId}`, { signal })
    .run()
    .catch(error => {
      logError(new Error('Failed to fetch charity campaign', error))

      pushMessage({
        formattedMessage: intl.formatMessage(Common_FetchError),
        type: 'danger',
        ttl: 5000
      })

      throw error
    })
}

export function fetchTotalDonations(signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/charities/v1/user-data`, { signal })
    .run()
    .then(
      ({ totalDonationSum }) => totalDonationSum,
      error => {
        logError(new Error('Failed to fetch total donations', error))

        pushMessage({
          formattedMessage: intl.formatMessage(Common_FetchError),
          type: 'danger',
          ttl: 5000
        })

        throw error
      }
    )
}

export function fetchCharityUserData(pageEnd: number | null, signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(
    `${API_HOST}/charities/v1/user-data${pageEnd ? `/donations?pageStart=${pageEnd}` : ''}`,
    {
      signal
    }
  )
    .run()
    .catch(error => {
      logError(new Error('Failed to fetch charity user data', error))

      pushMessage({
        formattedMessage: intl.formatMessage(Common_FetchError),
        type: 'danger',
        ttl: 5000
      })

      throw error
    })
}

export function searchCharityCampaigns(
  text: string,
  benefit: CharityBenefitType | '',
  order: CharitySearchOrderType | '',
  pageEnd: number | null,
  signal?: AbortSignal
) {
  let url = `${API_HOST}/charities/browse/v1/search?text=${text}`

  if (order) {
    url += `&order=${order}`
  }

  if (benefit) {
    url += `&benefit=${benefit}`
  }

  if (pageEnd) {
    url += `&pageStart=${pageEnd}`
  }

  return enrichedAuthenticatedFetchData(url, { signal })
    .run()
    .catch(error => {
      logError(new Error('Failed to search campaigns', error))

      pushMessage({
        formattedMessage: intl.formatMessage(Common_FetchError),
        type: 'danger',
        ttl: 5000
      })

      throw error
    })
}

export function sendTaxReceipts(from: string, to: string, email: string) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Australia/Sydney'

  // Make sure time is set to start and end of day UTC
  const fromDate = new Date(`${from}T00:00:00`).toISOString()
  const toDate = new Date(`${to}T23:59:59`).toISOString()

  return enrichedAuthenticatedFetchData(
    `${API_HOST}/charities/v1/user-data/tax-receipt?from=${fromDate}&to=${toDate}&email=${email}&timezone=${timezone}&nativeAppName=${APP_NAME}`
  ).run()
}
