import { askForUserLocationNative, isOnNative } from './react-native-service'
import { logError } from '@tomra/datadog-browser-logging'
import { API_HOST, ENV, VIEWER_COUNTRY_STATE, countryStateMap, enrichedFetch, enrichedFetchData, intl } from '../lib'
import { pushMessage } from '../components/AppMessages'
import { Common_FetchError } from '../translations/messages'

const fallbackBounds = {
  getNorthEast: () => ({ lat: () => 0, lng: () => 0 }),
  getSouthWest: () => ({ lat: () => 0, lng: () => 0 })
}

const supportedCpTypes: LocationCategory[] = ['RVM', 'DEPOT', 'OTC', 'DONATION_STATION']

type GeoLocationResult = {
  coords: {
    latitude: number
    longitude: number
  }
}

let nativeUserLocationPromise

// Extending built-in Error doesn't work as expected with instanceof
// when transpiling ES6 down to ES5, therefore these custom error classes
// are defined with function constructors, rather than ES6's class syntax
export function GeolocationError(message: string) {
  // @ts-ignore
  this.name = 'GeolocationError'
  // @ts-ignore
  this.message = message
  // @ts-ignore
  this.stack = new Error().stack
}

GeolocationError.prototype = new Error()

export function DoesNotSupportGeolocationError() {
  // @ts-ignore
  this.name = 'DoesNotSupportGeolocationError'
  // @ts-ignore
  this.stack = new Error().stack
}

DoesNotSupportGeolocationError.prototype = new Error()

// Translate PositionErrors to GeolocationError to make it easier to check for (as PositionError does not have a publicly available constructor) https://developer.mozilla.org/en-US/docs/Web/API/PositionError
function rejectWithCustomError(originalReject) {
  return err => {
    const msg = err ? `Got PositionError (code: ${err.code})` : 'Geolocation rejected without Error'
    originalReject(new GeolocationError(msg))
  }
}

function findLocationViaNativeAppWrapper() {
  const listenerCallback = (nativeAction: any) => {
    // now that we've got answer from the native app, we're ready to fulfil the Promise signalling
    // whether or not the user-location request succeeded or not
    try {
      const parsedMessage = typeof nativeAction.data === 'object' ? nativeAction.data : JSON.parse(nativeAction.data)

      if (parsedMessage.type === 'USER_LOCATION_RESULT') {
        if (parsedMessage.error === true) {
          nativeUserLocationPromise.reject()
        } else {
          nativeUserLocationPromise.resolve(parsedMessage.payload)
        }
      }
    } catch (error) {
      // Don't care
    }
  }

  // need to use window.addEventListener on iOS and document.addEventListener for android
  window.addEventListener('message', listenerCallback)
  document.addEventListener('message', listenerCallback)

  askForUserLocationNative()

  return new Promise<GeoLocationResult>((resolve, reject) => {
    // save resolve() and reject() for later so that other methods in this component
    // are able to fulfill this Promise when appropriate
    nativeUserLocationPromise = { resolve, reject }
  })
}

function findLocationWithBrowserApi() {
  return new Promise<GeoLocationResult>((resolve, reject) => {
    if ('geolocation' in navigator) {
      window.navigator.geolocation.getCurrentPosition(resolve, rejectWithCustomError(reject))
    } else {
      reject(new DoesNotSupportGeolocationError())
    }
  })
}

export function findUserLocation() {
  const locationRequest = isOnNative() ? findLocationViaNativeAppWrapper() : findLocationWithBrowserApi()

  return locationRequest.then(
    ({ coords }) => {
      return { lat: coords.latitude, lng: coords.longitude }
    },
    err => {
      throw err
    }
  )
}

export function fetchAllLocations() {
  const f = enrichedFetchData(
    `${API_HOST}/mytomra/v1.0/locations/${VIEWER_COUNTRY_STATE}?includeExternal=${countryStateMap[VIEWER_COUNTRY_STATE].includeExternalLocations}`,
    {
      cache: 'no-store',
      headers: {
        accept: 'application/vnd.api+json'
      }
    }
  )

  return {
    run: () =>
      f.run().then(locations =>
        locations.map(({ id, attributes }) => {
          attributes.locationType = supportedCpTypes.includes(attributes.locationType) ? attributes.locationType : 'RVM'

          return {
            id,
            ...attributes
          }
        })
      ),
    abort: f.abort
  }
}

export function findCoordinatesForKeyword(
  mapSdk: typeof google.maps,
  searchTerm: string,
  bounds: google.maps.LatLngBounds
): Promise<{ result: google.maps.GeocoderResult[] | null; status: google.maps.GeocoderStatus }> {
  const geocodeSdk = new mapSdk.Geocoder()
  const { lat: northLat, lng: eastLng } = bounds?.getNorthEast() || fallbackBounds.getNorthEast()
  const { lat: southLat, lng: westLng } = bounds?.getSouthWest() || fallbackBounds.getNorthEast()

  return new Promise(resolve => {
    geocodeSdk.geocode(
      {
        address: searchTerm,
        bounds: { north: northLat(), east: eastLng(), south: southLat(), west: westLng() },
        region: ENV
      },
      (result, status) => resolve({ result, status })
    )
  })
}

export function fetchLocation(locationId: string, signal?: AbortSignal) {
  return enrichedFetch(`${API_HOST}/mytomra/v1.0/locations/details/${locationId}`, { cache: 'no-store', signal })
    .run()
    .then(
      ({ data, meta }: any) => {
        const attributes = data.attributes
        attributes.locationType = supportedCpTypes.includes(attributes.locationType) ? attributes.locationType : 'RVM'

        return {
          ...attributes,
          ...meta
        }
      },
      error => {
        logError(new Error('Failed to fetch location details', error))

        pushMessage({
          formattedMessage: intl.formatMessage(Common_FetchError),
          type: 'danger',
          ttl: 5000
        })

        throw error
      }
    )
}
