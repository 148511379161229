import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRouteError } from 'react-router-dom'
import { logInfo } from '@tomra/datadog-browser-logging'
import { ErrorIcon, ConnectionDownIcon } from './icons'
import { Common_GeneralError, Common_FetchError, AppUpdatedDialog_Button } from '../translations/messages'
import { Button, Loading } from './shared'
import { intl } from '../lib'
import { type FallbackProps } from 'react-error-boundary'

const Container = styled.div`
  min-height: 100vh;
  background-color: var(--colors-yellow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: var(--spacing-xl);
  text-align: center;

  > * {
    max-width: var(--max-page-width);
  }
`

const ErrorMsg = styled.p`
  padding: var(--spacing-md);
  border-radius: var(--radius-sm);
  background-color: rgba(255, 0, 0, 0.15);
  width: 100%;
  font-size: var(--font-size-sm);
`

const ErrorFallback = ({ errorMsg, onReset }: { errorMsg?: string; onReset: () => void }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onResetButton = () => {
    setIsLoading(true)
    onReset()
  }

  return (
    <Container>
      {errorMsg?.match(/(XHR error)|(failed to fetch)|(fetch error)|(aborted)|(request failed)|(load failed)/gi) ? (
        <>
          <ConnectionDownIcon size="100" />
          <p>{intl.formatMessage(Common_FetchError)}</p>
        </>
      ) : (
        <>
          <ErrorIcon size="100" />
          <p>{intl.formatMessage(Common_GeneralError)}</p>
          <ErrorMsg>{errorMsg}</ErrorMsg>
        </>
      )}
      <Button onClick={onResetButton}>{isLoading ? <Loading /> : intl.formatMessage(AppUpdatedDialog_Button)}</Button>
    </Container>
  )
}

export const onGeneralErrorBoundary = error => {
  const errorMsg = typeof error === 'string' ? error : error?.message
  logInfo('General Error Boundary shown', { errorMsg })
}

export const GeneralErrorBoundary = ({ error, resetErrorBoundary }: FallbackProps) => {
  const errorMsg = typeof error === 'string' ? error : error?.message
  return <ErrorFallback errorMsg={errorMsg} onReset={resetErrorBoundary} />
}

export const RouterErrorBoundary = () => {
  const error = useRouteError() as any

  let errorMsg = ''

  if (typeof error === 'string') {
    errorMsg = error
  } else if (error?.message) {
    errorMsg = error.message
  } else if (error?.statusText) {
    errorMsg = error.statusText
  } else {
    errorMsg = 'Unknown error'
  }

  useEffect(() => {
    logInfo('Router Error Boundary shown', { error, errorMsg })
  }, [error, errorMsg])

  return <ErrorFallback errorMsg={errorMsg} onReset={() => (window.location.href = '/')} />
}
