import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme, APP_NAME } from '../../../lib'
import { isBefore, isAfter, startOfDay } from 'date-fns'

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-sm);
`

const isAfterStartTimeMap = {
  RETURNANDEARN: isAfter(new Date(), startOfDay(new Date('2023-10-21'))),
  CDSVICWEST: isAfter(new Date(), startOfDay(new Date('2024-08-12')))
}

const isBeforeEndTimeMap = {
  RETURNANDEARN: isBefore(new Date(), startOfDay(new Date('2024-10-20'))),
  CDSVICWEST: isBefore(new Date(), startOfDay(new Date('2024-11-10')))
}

export const AustraliaCampaignCard = () => {
  const isWithinTimeframe = isAfterStartTimeMap[APP_NAME] && isBeforeEndTimeMap[APP_NAME]

  if (APP_NAME === 'RETURNANDEARN' && isWithinTimeframe) {
    return (
      <Link to="/charities/56e49352-cdf8-4ebd-8eef-90898ea06d9a">
        <Image
          className="zoomIn cursor-pointer"
          src={theme.images.campaign}
          alt="Transporting Seriously iIl Country Kids to City Hospitals"
        />
      </Link>
    )
  }

  if (APP_NAME === 'CDSVICWEST' && isWithinTimeframe) {
    return (
      <Link to="/charities/c07b8b73-7a24-4213-8d63-9a3a27e83b02">
        <Image
          className="zoomIn cursor-pointer"
          src={theme.images.campaign}
          alt="Help Rebuild our Rural Communities After Natural Disasters"
        />
      </Link>
    )
  }

  return null
}
