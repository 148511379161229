import React from 'react'
import styled from 'styled-components'
import { Currency, Loading } from '../shared'
import { intl } from '../../lib'
import { useRefundsQuery } from '../../hooks'
import {
  RefundStatisticsSwiper_AvailableBalance,
  RefundStatisticsSwiper_TotalEarnings,
  RefundStatisticsSwiper_TotalPaidOut
} from '../../translations/messages'

const OuterContainer = styled.div`
  margin: var(--spacing-xl) 0;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  > div:nth-child(2) {
    margin: 0 var(--spacing-md);
  }
`

const InnerContainer = styled.div`
  text-align: center;

  > small {
    font-weight: bold;
  }
`

export const RefundStatistics = () => {
  const { data } = useRefundsQuery()

  return data ? (
    <OuterContainer>
      <Container>
        <InnerContainer>
          <small>{intl.formatMessage(RefundStatisticsSwiper_AvailableBalance)}</small>
          <h2>
            <Currency amount={data.balanceStatistics?.totalAmount || 0} currency={data.currency || 'AUD'} />
          </h2>
        </InnerContainer>

        <InnerContainer>
          <small>{intl.formatMessage(RefundStatisticsSwiper_TotalPaidOut)}</small>
          <h2>
            <Currency amount={data.payoutStatistics?.totalAmount || 0} currency={data.currency || 'AUD'} />
          </h2>
        </InnerContainer>

        <InnerContainer>
          <small>{intl.formatMessage(RefundStatisticsSwiper_TotalEarnings)}</small>
          <h2>
            <Currency amount={data.recycledStatistics?.totalAmount || 0} currency={data.currency || 'AUD'} />
          </h2>
        </InnerContainer>
      </Container>
    </OuterContainer>
  ) : (
    <Loading className="centerFlex" />
  )
}
