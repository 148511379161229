import { QueryClient, useQuery, useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { logError } from '@tomra/datadog-browser-logging'
import {
  fetchProfile,
  fetchPayoutMethods,
  fetchBadges,
  fetchActivities,
  fetchRefunds,
  fetchVouchers,
  updatePayout,
  fetchAchievements,
  fetchTotalDonations,
  fetchCharityUserData,
  fetchCharityCampaign,
  searchCharityCampaigns,
  fetchLocation
} from '../services'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Infinity
    }
  }
})

export const useProfileQuery = () => {
  return useQuery({
    queryKey: ['profile'],
    queryFn: ({ signal }) => fetchProfile(signal)
  })
}

export const usePayoutMethodsQuery = () => {
  return useQuery({
    queryKey: ['payoutMethods'],
    queryFn: ({ signal }) => fetchPayoutMethods(signal)
  })
}

export const usePayoutMethodMutation = () => {
  return useMutation({
    mutationFn: (payoutConfig: PayoutUpdateType) =>
      updatePayout(payoutConfig.method, payoutConfig.type, payoutConfig.recipient),
    onSettled: (updatedData, error) => {
      if (error) {
        logError(new Error('Failed to update payout', error))
      }

      updatedData
        ? queryClient.setQueryData(['payoutMethods'], () => updatedData)
        : queryClient.invalidateQueries({ queryKey: ['payoutMethods'] })
    }
  })
}

export const useBadgesQuery = () => {
  return useQuery({
    queryKey: ['badges'],
    queryFn: ({ signal }) => fetchBadges(signal)
  })
}

export const useActivitiesQuery = () => {
  return useInfiniteQuery({
    queryKey: ['activities'],
    queryFn: ({ pageParam, signal }) => fetchActivities(pageParam, signal),
    initialPageParam: '',
    getNextPageParam: lastPage => lastPage.pageEnd
  })
}

export const useRefundsQuery = () => {
  return useQuery({
    queryKey: ['refunds'],
    queryFn: ({ signal }) => fetchRefunds(signal)
  })
}

export const useVouchersQuery = () => {
  return useQuery({
    queryKey: ['vouchers'],
    queryFn: ({ signal }) => fetchVouchers(signal)
  })
}

export const useAchievementsQuery = () => {
  return useQuery({
    queryKey: ['achievements'],
    queryFn: ({ signal }) => fetchAchievements(signal)
  })
}

export const useDonationsSumQuery = () => {
  return useQuery({
    queryKey: ['donationsSum'],
    queryFn: ({ signal }) => fetchTotalDonations(signal)
  })
}

export const useDonationsQuery = () => {
  return useInfiniteQuery({
    queryKey: ['donations'],
    queryFn: ({ pageParam, signal }) => fetchCharityUserData(pageParam, signal),
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.pageEnd
  })
}

export const useCharityCampaignQuery = (campaignId: string) => {
  return useQuery({
    queryKey: ['campaign', campaignId],
    queryFn: ({ signal }) => fetchCharityCampaign(campaignId, signal)
  })
}

export const useCharitySearchQuery = (
  text: string,
  benefit: CharityBenefitType | '',
  order: CharitySearchOrderType | ''
) => {
  return useInfiniteQuery({
    queryKey: ['donations', text, benefit, order],
    queryFn: ({ pageParam, signal }) => searchCharityCampaigns(text, benefit, order, pageParam, signal),
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.pageEnd
  })
}

export const useLocationDetailsQuery = (locationId: string, refetchInterval?: number) => {
  return useQuery({
    queryKey: ['location', locationId],
    queryFn: ({ signal }) => fetchLocation(locationId, signal),
    refetchInterval: refetchInterval || false
  })
}
